import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getPasswordResetToken } from "../services/operations/authAPI"

const ForgotPassword = () => {

    const [email, setEmail] = useState("")
    const [emailSent, setEmailSent] = useState(false)
    const dispatch = useDispatch()
     const { loading } = useSelector((state) => state.auth)

    const handleOnSubmit = (e) => {
    e.preventDefault()
        dispatch(getPasswordResetToken(email, setEmailSent))
    }


  return (
    <div>

        <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center bg-darkwhite">
            {loading ? (
                <div className="spinner"></div>
            ) : (
                <div className="max-w-[500px] p-4 lg:p-8">
                <h1 className="text-[1.875rem] font-semibold leading-[2.375rem] text-black">
                    {!emailSent ? "Reset your password" : "Check email"}
                </h1>
                <p className="my-4 text-[1.125rem] leading-[1.625rem] text-black">
                    {!emailSent
                    ? "Have no fear. We'll email you instructions to reset your password. If you dont have access to your email we can try account recovery"
                    : `We have sent the reset email to ${email}`}
                </p>
                <form onSubmit={handleOnSubmit}>
                    {!emailSent && (
                    <label className="w-full">
                        <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black">
                        Email <sup className="text-pink-200">*</sup>
                        </p>
                        <input
                        required
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter email address"
                        className="w-full p-3 rounded-[5px] focus:outline-none border border-darkblue"
                        />
                    </label>
                    )}
                    <button
                    type="submit"
                    className="mt-6 w-full rounded-[8px] bg-black py-[12px] px-[12px] font-medium  text-white"
                    >
                    {!emailSent ? "Sumbit" : "Resend Email"}
                    </button>
                </form>
                <div className="mt-6 flex items-center justify-between">
                    <Link to="/signin">
                    <p className="flex items-center gap-x-2 text-darkblue">
                        <BiArrowBack /> Back To Login
                    </p>
                    </Link>
                </div>
                </div>
            )}
        </div>

    </div>
  )
}



export default ForgotPassword

