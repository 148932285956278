import React from 'react'

const NotFound = () => {
  return (
    <div className='flex h-screen text-3xl justify-center mt-20'>
      <div>
        401 Unauthorized
      </div>
    </div>
    
  )
}

export default NotFound
