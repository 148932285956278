export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Browse Job",
    path: '/browse',
  },
  // {
  //   title: "Career Advice",
  //   path: "/careeradvice",
  // },
  {
    title: "Contact Us",
    path: "/contactus",
  },
];

